import React, {useState} from "react"
import {Footer} from "../components/footer/Footer.js"
import {Header} from "../components/header/Header.js"
import Link from "gatsby-link"
import MediaPicto from "../components/query/PictoQuery"
import {graphql, useStaticQuery} from "gatsby"
import "../styles/marques.css"
import ReactHtmlParser from "react-html-parser"
import SearchForm from "../components/searchForm/SearchForm.js"

const BrandsPage = () => {
    const [search, setSearch] = useState("")

    const updateSearch = (e) => {
        //console.log(e)
        setSearch(e.target.value)
        // console.log(search);
    }

    const marquesdata = useStaticQuery(graphql`
    query {
      allWpPage {
        edges {
          node {
            title
            content
          }
        }
      }
      allBrandS3(sort: { fields: hit___name, order: [ASC] }) {
        edges {
          node {
            hit {
              name
            }
          }
        }
      }
    }
  `)
    const marquesS3 = marquesdata.allBrandS3.edges

    function correctLogoName(marque) {
        marque = marque.toLowerCase()
        marque = marque.split(" ").join("_")
        return marque
    }

    function generateSlug(item) {
        item = item.toLowerCase()
        item = item.split(" ").join("-")
        return item
    }

    function correctFolderName(marque) {
        marque = marque.toUpperCase()
        marque = marque.split(" ").join("+")
        return marque
    }

    function changeImageUrlToThumb(url) {
        if (url.includes(".jpg")) {
            url = url.replace(".jpg", "-thumb.jpg")
        } else if (url.includes(".png")) {
            url = url.replace(".png", "-thumb.png")
        }
        return url
    }

    //changeTheURL("nicotra")

    function changeTheURL(name) {
        let url = typeof window !== "undefined" ? window.location.origin : ""
        let correctName = name

        url = url.replace("brand.", "")
        url = url.replace("contact.", "")
        url = url.replace("telechargements.", "")

        if (url.includes("www.")) {
            if (url.includes("http://")) {
                url = url.replace("http://www.", "http://www." + correctName + ".")
            } else {
                url = url.replace("https://www.", "http://www." + correctName + ".")
            }
        } else {
            if (url.includes("http://")) {
                url = url.replace("http://", "http://" + correctName + ".")
            } else {
                url = url.replace("https://", "http://" + correctName + ".")
            }
        }

        return url
    }

    let marque_logo_url = ""

    return (
        <div>
            <Header/>

            <div className="bannMarqueBox">
                <div
                    className="bannBox"
                    data-sizes="(max-aspect-ratio: 2550/770) 331vh"
                >
                    <SearchForm {...{search, updateSearch, to: "/searchpage"}} />
                </div>
            </div>
            <div className="marques-main-box">
                <div className="marques-pres-box">
                    <div className="pres-elements-box">
                        <p className="text-pres">
                            {marquesdata.allWpPage.edges.map((page, index) => {
                                if (page.node.title === "Les marques") {
                                    return ReactHtmlParser(page.node.content)
                                }
                            })}
                        </p>
                    </div>
                </div>

                <div className="marques-grid marques-main">
                    {marquesS3.map((marque, index) => {
                        marque_logo_url =
                            "https://media-setem.s3.eu-central-1.amazonaws.com/articles/" +
                            correctFolderName(marque.node.hit.name) +
                            "/00_LOGO-ETIQUETTES/marque_logo_" +
                            correctLogoName(marque.node.hit.name) +
                            ".png"
                        return (
                            <div className="marques-content marques-content-update">
                                <div className="marque-url-img container-img">
                                    {/*<img className="marque-img" src={item.url} />*/}
                                    <Link
                                        className="marques"
                                        to={`${changeTheURL(
                                            generateSlug(marque.node.hit.name)
                                        )}/${generateSlug(marque.node.hit.name)}`}
                                    >
                                        <img
                                            src={changeImageUrlToThumb(marque_logo_url)}
                                            className="logo-img"
                                        />
                                    </Link>
                                </div>
                                <div className="marque-titre-img marque-title-img-update">
                                    <Link
                                        className="marques"
                                        to={`${changeTheURL(
                                            generateSlug(marque.node.hit.name)
                                        )}/${generateSlug(marque.node.hit.name)}`}
                                    >
                                        <span className="img-titre">{marque.node.hit.name.replaceAll("_", " ")}</span>
                                    </Link>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {/* <div className="containPictoBox">
                    <MediaPicto/>
                </div>*/}
            </div>
            <Footer/>
        </div>
    )
}
export default BrandsPage
